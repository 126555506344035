<template>
<div>
    <p>Payment products allow you to interact with our payment APIs.</p>

    <b-card>
     <b-row ref="row">

                <!-- Item Name -->
                <b-col md="4">
                    <b-form-group label="Start Date" label-for="item-name">
                        <b-form-datepicker id="startdatepicker-buttons" today-button reset-button close-button v-model="startDate" locale="en" />
                    </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col md="4">
                    <b-form-group label="End Date" label-for="item-name">
                        <b-form-datepicker id="enddatepicker-buttons" today-button reset-button close-button v-model="endDate" locale="en" />
                    </b-form-group>
                </b-col>

                <!-- Profession -->

                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                    <b-button variant="outline-danger" class="mt-0 mt-md-2" @click="showTables()">
                        <feather-icon icon="SearchIcon" class="mr-25" />
                        <span>Search</span>
                    </b-button>
                </b-col>
                <b-col cols="12">
                    <hr>
                </b-col>
            </b-row>
        <!-- input search -->
        

        <!-- table -->
        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm }" :pagination-options="{
        enabled: true,
        perPage:pageLength
        
      }" v-show="showTable">
            <template slot="table-row" slot-scope="props">
                <!-- Column: Status -->
                
                <span v-if="props.column.field === 'created_at'">
                        {{ props.row.created_at | formatDate}}
                    
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                    <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                            </template>
                            <b-dropdown-item>
                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                <span>Disabled</span>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <feather-icon icon="TrashIcon" class="mr-50" />
                                <span>Delete</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card>
</div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
} from 'bootstrap-vue'
import {
    VueGoodTable
} from 'vue-good-table'
import $ from 'jquery'
import store from '@/store'
import moment from "moment"
import numeral from "numeral"

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BCard,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormDatepicker,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: 'Name',
                    field: 'name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search Service Products',
                    },
                },
                {
                    label: 'Campaign Id',
                    field: 'campaign_id',
                },
                {
                    label: 'Report ID',
                    field: 'report_id',
                },
                {
                    label: 'Shortcode',
                    field: 'short_code',
                },
                {
                    label: 'Sent',
                    field: 'sent',
                },

                {
                    label: 'Success',
                    field: 'success',
                },
                 {
                    label: 'Bill',
                    field: 'to_bill',
                },
                {
                    label: 'Date',
                    field: 'created_at',
                },
            ],
            rows: [
            ],
            searchTerm: '',
            startDate: '',
            endDate: '',
            showTable: false,
            status: [{
                1: 'Active',
                2: 'Pending',
                3: 'Rejected',
            }, {
                1: 'light-success',
                2: 'light-primary',
                3: 'light-danger',
            }],
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Active: 'light-success',
                Pending: 'light-primary',
                Rejected: 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    methods: {
        showTables() {

            if (this.endDate == "" || this.startDate == "") {
                this.$swal({
                    title: 'Error!',
                    text: ' You need to select the date',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            } else {
                let vm = this
                vm.rows = []
                $.get({
                    url: store.state.rootUrl + 'subscription/v1/view/message_reports',
                    type: "get",
                    async: true,
                    data: {
                        start: vm.startDate,
                        end: vm.endDate,
                        limit: 500
                    },
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                    },
                    success: function (response, status, jQxhr) {

                        if (response.data.code != 200) {
                            vm.$swal({
                                title: 'Error!',
                                text: response.data.message,
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                        } else {
                            vm.showTable = true
                            vm.rows = response.data.data;
                        }
                    },
                    error: function (jQxhr, status, error) {

                    }
                });

            }
        },
    },
    filters: {
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        formatAmount: function (value) {
            return numeral(value).format("0,0.00");
        },
    }
}
</script>
